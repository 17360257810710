// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-board-jsx": () => import("./../../../src/pages/board.jsx" /* webpackChunkName: "component---src-pages-board-jsx" */),
  "component---src-pages-devs-jsx": () => import("./../../../src/pages/devs.jsx" /* webpackChunkName: "component---src-pages-devs-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-publicboard-jsx": () => import("./../../../src/pages/publicboard.jsx" /* webpackChunkName: "component---src-pages-publicboard-jsx" */)
}

